import React, { useState, useEffect } from "react";
import "./service-pages.css"
import { CiCircleChevRight } from "react-icons/ci";
import {Link} from "react-router-dom"
import { useNavigate } from "react-router-dom"; 

function Projektiranje(props) {
  
  const navigate = useNavigate(); 

  return (

    <div className='services-wrapper'>
{/* Wavy Header */}
      <div className="wavy-header"></div>
      
      <div className='services-main-content--right'>

            <div className='subtitle'>
                  O projektiranju
            </div>
            <div className='subtitle--paragraph'>
              Projektiranje je jedna od djelatnosti naše tvtrke. 
              Bitno je naglasiti da projektiranje nije samo crtanje već za sobom povlači 
              niz drugih aktivnosti i elemenata koje je potrebno uskladiti s ciljem stvaranja 
              što bolje vrijednosti za naše klijente.
              Zadatak arhitekta je uklopiti projekt s parcelom zadovoljavajući pri tome sve 
              zakonske standarde, estetiku, funkcionalnost objekta te uklapanje u okoliš – od 
              same analize parcele, prikupljanja parametara za vrstu, veličinu i namjenu objekta, 
              do projektiranja i oblikovanja.
            </div>


            <div className='subtitle'>
                Projektiranje i izvedba u koracima
            </div>
            <div className='service--step-list--container'>
              <ul className='service--step-list'>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>PROVJERA MOGUCNOSTI GRADNJE</h3>
                    <span>Ispitivanje lokacijskih uvjeta</span>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>IZRADA IDEJNE SLIKE</h3>
                    <span>Prema željama investitora i mogućnostima gradnje</span>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>TRAZENJE POSEBNIH UVJETA</h3>
                    <span>Mišljenje javnopravnih tijela</span>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>IZRADA IDEJNOG RJESENJA</h3>
                    <span>Gdje se usklađuju želje investitora i mogućnosti prema prostornom planu</span>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>IZRADA GLAVNOG PROJEKTA</h3>
                    <span>Skup projekata svih struka koje sudjeluju u projektiranju</span>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>POTVRDA PROJEKTA</h3>
                    <span>Od javnopravnih tijela (MUP, HEP, Voda, Sanitarna...</span>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>GRADJEVINSKA DOZVOLA</h3>
                    <span>Sve do gradjevinske dozvole</span>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>PLACANJE KOMUNALNOG I VODNOG DOPRINOSA</h3>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>GRADNJA</h3>
                    <span>U skladu s građevinskom dozvolom i glavnim projektom</span>
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div>
                    <h3 className='service--step-list--list--title'>UPORABNA DOZVOLA</h3>
                    <span>Kojom se utvrđuje da je građevina izvedena prema projektu i građevinskoj dozvoli</span>
                  </div>
                </li>
                <li>
                  <div>
                  <div className='subtitle'>
                    Troškovi projektiranja
                  </div>
                  
                <ul className='service--step-list--contacts'>
                  <li className='service--step-list--item'>
                    <div>
                      <span>Kontaktirajete nas i izračunat ćemo za vas ukupne troškove projektiranja vašeg objekta!</span>
                    </div>
                </li>
                <li className='service--step-list--icon---contact' >
                    <Link to="/contact">        
                        <button 
                            className="service--step-list--icon---contact--btn"
                            onClick={() => navigate('/contact')} 
                        >
                            KONTAKT
                        </button>
                    </Link>
                </li>
              </ul>
             </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
  
  )
}

export default Projektiranje






  // const sliderId = '46';  
  // useEffect(() => {
  //   const fetchSliderData = async () => {
  //     try {
  //       const response = await axios.get(`http://localhost:5000/api/slider/${sliderId}`);
  //       const fetchedImage = {
  //         url: `http://localhost:5000${response.data.picture_url}`,
  //         alt: response.data.title,
  //         title: 'TREBATE USLUGE ZA PROJEKTIRANJE?',
  //         description: 'Nudimo usluge projektiranja svih vrsta objekata, stambene i poslovne namjene, novogradnje i dogradnje.',
          
  //       };
  //       setImages([fetchedImage]);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching slider data:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchSliderData();
  // }, [sliderId]);