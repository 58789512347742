import React from "react";
import { useNavigate } from "react-router-dom";
import "./About.css";

import AboutImg1 from "../about/images/aboutuspic01.jpg";
import AboutImg2 from "../about/images/aboutuspic02.svg";
import AnteAvatar from "../about/images/anteav.png";
import KarmenAvatar from "../about/images/karmenav.jpg";

function About() {
  const navigate = useNavigate();

  return (
    <div className="responsive-container-block bigContainer">
      <div className="aboutuspageTitle">
        O nama i našoj firmi...
      </div>

      <div className="responsive-container-block Container topContainer">
        <img className="mainImg" src={AboutImg2} alt="Our Mission" />
        <div className="allText aboveText">
          <p className="text-blk headingText">Tko smo mi?</p>
          <p className="text-blk subHeadingText">
            Građevinska tvrtka Nacrti-Šibenik d.o.o. osnovana je u Šibeniku 2023. godine te od tada kontinuirano posluje do danas.
          </p>
          <p className="text-blk description">
            Osnovna djelatnost tvrtke je projektiranja svih vrsta objekata, stambene i poslovne namjene, novogradnje i dogradnje nadzor pri gradnji privatnih i poslovnih objekata visokogradnje i niskogradnje legalizacije, etažiranje, procjena vrijednosti objekata i općenita savjetovanja.
          </p>
          <button
            className="explore"
            onClick={() => navigate('/references')}
          >
            NAŠE REFERENCE
          </button>
        </div>
      </div>

      <div className="responsive-container-block Container bottomContainer">
        <img className="mainImg" src={AboutImg1} alt="Our Vision" />
        <div className="allText bottomText">
          <p className="text-blk headingText">Što nam je cilj?</p>
          <p className="text-blk subHeadingText">
            Cilj tvrtke je našim projektima učiniti vas zadovoljnima, jer upravo to je naše najveće ostvaranje.
          </p>
          <p className="text-blk description">
            Stručni tim tvrtke Nacrti-Šibenik d.o.o stečenim dugogodišnjim znanjem i stalnom edukacijom, tu je da vam pruži najstručnija rješenja. Odlika izrazito brze i kvalitetne usluge rezultirala je brojnim pohvalama i zadovoljstvom naših stalnih klijenata.
          </p>
          <button
            className="explore"
            onClick={() => {
              navigate('/services/projektiranje');
              // Force scroll handling after navigation
              setTimeout(() => {
                window.dispatchEvent(new Event('scroll'));
              }, 0);
            }}
          >
            NAŠE USLUGE
          </button>
        </div>
      </div>

      <hr />
      <div className="aboutuspageTitle">Naš stručni tim</div>

      <div className="responsive-container-block Container topContainer">
        <img className="mainImg avatar" src={AnteAvatar} alt="ante" />
        <div className="allText bottomText">
          <p className="text-blk subHeadingText teammember">
            Ante Nakić, mag. ing. aedif.
          </p>
          <p className="text-blk description teammember">
             Projektant voditelj
          </p>
          <p>
            <a className="txt-blk email" href="mailto:ante.nakic@nacrtisibenik.hr">ante.nakic@nacrtisibenik.hr</a>
          </p>
          
        </div>
      </div>
      <div className="responsive-container-block Container bottomContainer">
        <img className="mainImg avatar" src={KarmenAvatar} alt="Karmen" />
        <div className="allText bottomText">
          <p className="text-blk subHeadingText teammember">
            Karmen Jakus mag. ing. aedif. G6396
          </p>
          <p className="text-blk description teammember">
            Projektant
          </p>  
          <p>
            <a className="txt-blk email" href="mailto:karmen.jakus@nacrtisibenik.hr">karmen.jakus@nacrtisibenik.hr</a>
          </p>
         
        </div>
      </div>
    </div>
  );
}

export default About;
