import React, { useState, useEffect } from "react";
import axios from "axios";

import "./service-pages.css"

import { CiCircleChevRight } from "react-icons/ci";
import {Link} from "react-router-dom"
import { useNavigate } from "react-router-dom"; 


function Procjena(props) {

  const navigate = useNavigate(); 


  return (

    
    <div className='services-wrapper'>

      <div className='services-main-content--right'>

            <div className='subtitle'>
               O savjetovanju
            </div>
            <div className='subtitle--paragraph'>
                Postupak kojim graditeljski vještak određuje njenu vrijednost u sadašnjem vremenu uzimajući u razmatranje moguće utjecaje (pravne, ekonomske, urbanističke, građevinske…) u budućem vremenu.
            </div> 

            <br />
            <div className='subtitle'>
            Tehničko savjetovanje se može provesti nad slijedećim vrstama objekata:
            </div>
            <div className='service--step-list--container'>
              <ul className='service--step-list'>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  stanove
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  kuće
                  </div>
                </li>
                <div className='separator--horizontal'></div>     
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  uredske prostore i lokale
                </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  ugostiteljske objekte
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  skladišne i proizvodne pogone
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  građevinska zemljišta
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  poljoprivredna zemljišta
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  robne kuće
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  benzinske pumpe
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  specifične nekretnine
                  </div>
                </li>
                <div className='separator--horizontal'></div>
               
                <li>
                  <div>
                  <div className='subtitle'>
                  Troškovi procjene objekata
                  </div>
                  
                  <ul className='service--step-list--contacts'>
                  <li className='service--step-list--item'>
                    <div>
                      <span>Kontaktirajete nas i izračunat ćemo za vas ukupne troškove projektiranja vašeg objekta!</span>
                    </div>
                </li>
                <li className='service--step-list--icon---contact' >
                    <Link to="/contact">        
                        <button 
                            className="service--step-list--icon---contact--btn"
                            onClick={() => navigate('/contact')} 
                        >
                            KONTAKT
                        </button>
                    </Link>
                </li>
              </ul>
             </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  
  )
}

export default Procjena