import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./References.css";

import { Oval } from 'react-loader-spinner';
import {
  brodarica1, brodarica2, brodarica3, brodarica4, brodarica5,
  dubrava1, dubrava2, dubrava3, dubrava4, dubrava5, dubrava7, dubrava8, dubrava9, dubrava10, dubrava11, dubrava12,
  primosten1, primosten2, primosten3, primosten4, primosten5, primosten6,
  tribunj1, tribunj2, tribunj3, tribunj4, tribunj5,
  vodice1, vodice2, vodice3, vodice4,
} from './referencesData';

function References() {
  const projects = [
    {
      title: "Brodarica",
      images: [brodarica1, brodarica2, brodarica3, brodarica4, brodarica5],
    },
    {
      title: "Dubrava",
      images: [
        dubrava2, dubrava1, dubrava3, dubrava4, dubrava5, dubrava7, dubrava8, dubrava9, dubrava10, dubrava11, dubrava12,
      ],
    },
    {
      title: "Primosten",
      images: [
        primosten1, primosten2, primosten3, primosten4, primosten5, primosten6,
      ],
    },
    {
      title: "Tribunj",
      images: [tribunj1, tribunj2, tribunj3, tribunj4, tribunj5],
    },
    {
      title: "Vodice",
      images: [vodice1, vodice2, vodice3, vodice4],
    },
  ];

  const [loadingStates, setLoadingStates] = useState(
    Array(projects.length).fill(true)
  );
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentProjectImages, setCurrentProjectImages] = useState([]);

  useEffect(() => {
    projects.forEach((project, projectIndex) => {
      const loadImages = () => {
        const imagePromises = project.images.map((src) => {
          const img = new Image();
          img.src = src;
          return new Promise((resolve) => {
            img.onload = resolve;
            img.onerror = resolve; // Handle errors to avoid blocking the UI
          });
        });

        Promise.all(imagePromises).then(() => {
          setLoadingStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[projectIndex] = false;
            return newStates;
          });
        });
      };

      loadImages();
    });
  }, [projects]);

  const openLightbox = (images, index) => {
    setCurrentProjectImages(images);
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <>
      <div className="references-page--container">
        <div className="references-pages--title">Pogledajete naše reference...</div>
      </div>
      <div className="references-gallery-container">
        <div className="references-galleryWrapper">
          {projects.map((project, projectIndex) => (
            <div className="references-galleryItem" key={projectIndex}>
              <div className="references-galleryItem-content">
                {loadingStates[projectIndex] && (
                  <div className="references-gallery-overlay">
                    <Oval
                      height="50"
                      width="50"
                      color="#405783"
                      ariaLabel="loading"
                      visible={true}
                    />
                  </div>
                )}
                <img
                  src={project.images[0]} // Show the first image of the project
                  alt={project.title}
                  onClick={() => openLightbox(project.images, 0)}
                  className={`references-galleryImage ${loadingStates[projectIndex] ? 'loading' : ''}`}
                />
                {!loadingStates[projectIndex] && (
                  <div className="references-imageTitle">{project.title}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={currentProjectImages[photoIndex]}
          nextSrc={
            currentProjectImages[(photoIndex + 1) % currentProjectImages.length]
          }
          prevSrc={
            currentProjectImages[
              (photoIndex + currentProjectImages.length - 1) %
              currentProjectImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + currentProjectImages.length - 1) %
              currentProjectImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % currentProjectImages.length)
          }
        />
      )}
    </>
  );
}

export default References;
