import React, { useState, useEffect } from "react";
import "./service-pages.css"
import {Link} from "react-router-dom"
import { useNavigate } from "react-router-dom"; 


function Etaziranje(props) {

  const navigate = useNavigate(); 

  return (

    
    <div className='services-wrapper'>

      <div className='services-main-content--right'>

            <div className='subtitle'>
                O etažiranju
            </div>
            <div className='subtitle--paragraph'>
                <p> 
                    Etažirati neku zgradu znači uspostaviti vlasništvo posebnih dijelova te zgrade 
                    (npr. stana, poslovnog prostora, garaže i sl.) i 
                    utvrditi omjer suvlasništva, što uključuje i omjer suvlasništva na 
                    parceli na kojoj je zgrada izgrađena i koja služi redovitoj upotrebi te zgrade.
                </p>
                <br/>
                <p>
                    Donošenjem Zakona o vlasništvu i drugim stvarnim pravima 
                    (“Narodne novine”, broj 91/96) i Zakona o zemljišnim knjigama 
                    (“Narodne novine”, broj 91/96) u Republici Hrvatskoj je stvorena dobra 
                    pravna podloga za uspostavu vlasništva na posebnim dijelovima suvlasničke 
                    nekretnine i njenu provedbu u zemljišne knjige, što je najčešće moguće učiniti 
                    kroz etažiranje.
                </p>
            </div> 
            <div className='subtitle'>
                Što se dobiva etažiranjem?
            </div>
            <div className='subtitle--paragraph'>
                <p> 
                    Etažiranjem se potpuno sređuje zemljišno – knjižno stanje Vašeg stana, 
                    poslovnog prostora, garaže, itd., odnosno cijele nekretnine (zemljišta i zgrade), 
                    te se dobiva sloboda raspolaganja, odnosno zakonit promet nekretninom (zemljištem 
                    i zgradom), odnosno njenim posebnim dijelovima (stanom, poslovnim prostorom, 
                    garažom, itd.)
                </p>
                
            </div> 


            <div className='subtitle'>
                Troškovi etažiranja
            </div>
            <ul className='service--step-list--contacts'>
                <li className='service--step-list--item'>
                <div>
                    <span>Kontaktirajete nas i izračunat ćemo za vas ukupne troškove projektiranja vašeg objekta!</span>
                </div>
                </li>
                <li className='service--step-list--icon---contact' >
                    <Link to="/contact">        
                        <button 
                            className="service--step-list--icon---contact--btn"
                            onClick={() => navigate('/contact')} 
                        >
                            KONTAKT
                        </button>
                    </Link>
                </li>
            </ul>
            </div>
            

      </div>
 
  
  )
}

export default Etaziranje



