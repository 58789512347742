import "./Services.css";
import "./service-pages/service-pages.css";
import React, {useEffect} from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import StaticContentSidebar from "./StaticContentSidebar";

export default function Services(props) {
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const tabs = document.querySelector(".tabs");
      if (tabs) {
        if (window.scrollY > 100) {
          tabs.style.top = "60px";
        } else {
          tabs.style.top = "100px"; // Default top value
        }
      } else {
        console.warn('Tabs element not found');
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Redirect to /services/projektiranje if the path is exactly /services
  if (location.pathname === "/services") {
    return <Navigate to="/services/projektiranje" />;
  }

  return (
    <div className="services-wrapper">
      <StaticContentSidebar />
      <div className="services-main-content">
        <Outlet /> {/* Nested routes will be rendered here */}
      </div>
    </div>
  );
}
