import React, { useState, useEffect } from "react";
import ImgSlider from "../../components/sliderv2/ImgSlider";
import Cards from "../../components/cards/Cards";
import { sliderData } from "../home/sliderData"; // Adjust the path as needed

function Home(props) {    
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        // const response = await axios.get('http://localhost:5000/api/slider');
        // const fetchedImages = response.data.map(image => ({
        //   url: `http://localhost:5000${image.picture_url}`, 
        //   alt: image.title,
        //   title: image.title,
        //   description: image.description,
        //   isVideo: false, // Assuming all fetched items are images by default TODO
        // }));

        // Combine fetched images with hardcoded videos
        const combinedData = [
          // ...fetchedImages,
          ...sliderData
        ];

        setImages(combinedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching slider data:', error);
        setLoading(false);
      }
    };

    fetchSliderData();
  }, []);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <ImgSlider
            images={images}
            showControlsArrows={true}
            showControlsDots={true}
            showText={true}
            containerHeight={700}
            textPosition={{ top: 35, left: 20 }} 
            titleSize={3.2}
            descriptionSize={2} 
            titleColor="#ffffff"
            descriptionColor="#ffffab" 
            autoTransition={true} 
          />
          <Cards path={props.path} />
        </>
      )}
    </>
  );
}

export default Home;
