import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './StaticContentSidebar.css';

function StaticContentSidebar() {
  const location = useLocation();
  const [_, forceUpdate] = useState(0);  // State to trigger re-renders

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = document.querySelector('.tabs');
      const scrollY = window.scrollY;
      const isMobile = window.innerWidth <= 768;

      if (sidebar) {
        if (isMobile) {
          const newTop = Math.max(100 - scrollY, -150);
          sidebar.style.top = `${newTop}px`;
        } else {
          const maxTop = 60;
          const newTop = Math.max(100 - scrollY, maxTop);
          sidebar.style.top = `${newTop}px`;
        }
      }
    };

    handleScroll(); // Ensure it's called on initial render and every location change

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [location]);

  // Force re-render when location changes
  useEffect(() => {
    forceUpdate((n) => n + 1);
  }, [location]);

  return (
    <div className='tabs'>
      <ul className="tabs-list">
        <li className="tabs-item">
          <NavLink className="tabs-item--link" activeClassName="active" to="/services/projektiranje">
            PROJEKTIRANJE
          </NavLink>
        </li>
        <li className="tabs-item">
          <NavLink className="tabs-item--link" activeClassName="active" to="/services/procjena">
            PROCJENA OBJEKTA
          </NavLink>
        </li>
        <li className="tabs-item">
          <NavLink className="tabs-item--link" activeClassName="active" to="/services/nadzor">
            NADZOR
          </NavLink>
        </li>
        <li className="tabs-item">
          <NavLink className="tabs-item--link" activeClassName="active" to="/services/etaziranje">
            ETAŽIRANJE
          </NavLink>
        </li>
        <li className="tabs-item">
          <NavLink className="tabs-item--link" activeClassName="active" to="/services/legalizacija">
            LEGALIZACIJA
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default StaticContentSidebar;
