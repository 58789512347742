import React, { useState, useEffect, useRef } from "react";
import { ArrowBigLeft, ArrowBigRight, Circle, CircleDot } from "lucide-react";
import { Oval } from 'react-loader-spinner'; // Import your loader
import "./ImgSlider.css";

export default function ImgSlider({
  images,
  showControlsArrows,
  showControlsDots,
  containerHeight,
  showText,
  textPosition,
  titleSize,
  descriptionSize,
  titleColor,
  descriptionColor,
  autoTransition 
}) {
  const [imageIndex, setImageIndex] = useState(0);
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [sliderLoaded, setSliderLoaded] = useState(false); // Indicates if slider is fully loaded
  const [initialLoadDone, setInitialLoadDone] = useState(false); // Indicates if initial load is done

  const len = images.length - 1;
  const intervalRef = useRef();
  const videoRefs = useRef(images.map(() => React.createRef())); // Create refs for videos

  // Check session storage for initial load flag
  useEffect(() => {
    const hasLoadedBefore = sessionStorage.getItem('initialLoadDone');
    if (hasLoadedBefore) {
      setInitialLoadDone(true);
      setLoading(false);
    }
  }, []);

  // Preload all images and videos
  useEffect(() => {
    const preloadMedia = async () => {
      return Promise.all(
        images.map(({ url, isVideo }) => {
          return new Promise((resolve, reject) => {
            if (isVideo) {
              const video = document.createElement('video');
              video.src = url;
              video.onloadeddata = resolve;
              video.onerror = reject;
            } else {
              const img = new Image();
              img.src = url;
              img.onload = resolve;
              img.onerror = reject;
            }
          });
        })
      );
    };

    preloadMedia()
      .then(() => {
        setLoading(false);
        setSliderLoaded(true); // Mark slider as fully loaded
        if (!initialLoadDone) {
          sessionStorage.setItem('initialLoadDone', 'true'); // Set initial load flag
          setInitialLoadDone(true);
        }
      })
      .catch((error) => {
        console.error('Error preloading media:', error);
        setLoading(false); // Hide loader even if there is an error
      });
  }, [images, initialLoadDone]);

  // Reset interval function
  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (autoTransition && !loading) { // Only set interval if not loading
      intervalRef.current = setInterval(() => {
        setImageIndex((prevIndex) => {
          if (prevIndex === len) {
            return 0;
          }
          return prevIndex + 1;
        });
      }, 4000); // Example interval; adjust as needed
    }
  };

  useEffect(() => {
    if (!loading && sliderLoaded) {
      resetInterval();
    }
    return () => clearInterval(intervalRef.current);
  }, [loading, sliderLoaded]);

  useEffect(() => {
    setDescriptionVisible(true);
    const timeout = setTimeout(() => setDescriptionVisible(false), 2000);
    return () => clearTimeout(timeout);
  }, [imageIndex]);

  useEffect(() => {
    // Reset video playback when imageIndex changes
    videoRefs.current.forEach((videoRef, index) => {
      if (videoRef.current && imageIndex === index) {
        videoRef.current.currentTime = 0; // Reset to start
        videoRef.current.play(); // Start playback
      }
    });
  }, [imageIndex]);

  function showNextImage() {
    setImageIndex((index) => {
      if (index === len) {
        return 0;
      }
      return index + 1;
    });
    resetInterval();
  }

  function showPrevImage() {
    setImageIndex((index) => {
      if (index === 0) {
        return len; 
      }
      return index - 1;
    });
    resetInterval();
  }

  function handleDotClick(index) {
    setImageIndex(index);
    resetInterval();
  }

  // Ensure there are images before rendering
  if (!images.length) {
    return <div>Loading...</div>;
  }

  return (
    <section aria-label="Image Slider" className="img-slider-container" style={{ width: "100%", position: "relative", height: `${containerHeight}px` }}>
      <a href="#after-image-slider-controls" className="skip-link">Skip Image Slider Controls</a>

      {loading && !initialLoadDone && ( // Show loader only during initial load
        <div className="img-slider-overlay">
          <Oval
            height="100"
            width="100"
            color="#405783" // Set your desired color here
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {!loading && sliderLoaded && ( // Render the slider only after loading is complete
        <>
          <div style={{ width: "100%", height: "100%", display: "flex", overflow: "hidden" }}>
            {images.map(({ url, alt, isVideo }, index) => (
              <div
                className={`slideshow--container ${loading ? 'loading' : ''}`}
                key={url}
                style={{
                  height: `${containerHeight}px`,
                  opacity: imageIndex === index ? 1 : 0,
                  transition: "opacity 0.7s ease",
                  position: "absolute", 
                  left: 0, 
                  top: 0, 
                }}
              >
                {isVideo ? (
                  <video
                    ref={videoRefs.current[index]} // Attach ref
                    src={url}
                    alt={alt}
                    aria-hidden={imageIndex !== index}
                    className="img-slider-video"
                    style={{ width: "100%", height: "100%", objectFit: "fill" }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                ) : (
                  <img
                    src={url}
                    alt={alt}
                    aria-hidden={imageIndex !== index}
                    className="img-slider-img"
                    style={{ width: "100%", height: "100%", objectFit: "fill" }}
                  />
                )}
              </div>
            ))}
          </div>

          {showText && (
            <div className="slideshow--text--container"
                style={{
                  top: `${textPosition.top}%`,
                  left: `${textPosition.left}%`
                }}>
              <div className="slideshow--text--container--wrapper">
                <div className={`slideshow-title ${descriptionVisible ? 'fade-in' : ''}`} style={{ fontSize: `${titleSize}em`, color: titleColor }}>
                  {images[imageIndex].title}
                </div>
                <div className={`slideshow-description ${descriptionVisible ? 'fade-in' : ''}`} style={{ fontSize: `${descriptionSize}em`, color: descriptionColor }}>
                  {images[imageIndex].description}
                </div>
              </div>
            </div>
          )}

          {showControlsArrows && (
            <>
              <button
                onClick={showPrevImage}
                className="img-slider-btn"
                style={{ left: 0 }}
                aria-label="View Previous Image"
              >
                <ArrowBigLeft aria-hidden />
              </button>
              <button
                onClick={showNextImage}
                className="img-slider-btn"
                style={{ right: 0 }}
                aria-label="View Next Image"
              >
                <ArrowBigRight aria-hidden />
              </button>
            </>
          )}

          {showControlsDots && (
            <div
              style={{
                position: "absolute",
                bottom: "2rem",
                left: "50%",
                translate: "-50%",
                display: "flex",
                gap: ".75rem",
                zIndex: "2",
              }}
            >
              {images.map((_, index) => (
                <div key={index}>
                  <button
                    className="img-slider-dot-btn"
                    aria-label={`View Image ${index + 1}`}
                    onClick={() => handleDotClick(index)}
                  >
                    {index === imageIndex ? (
                      <CircleDot aria-hidden />
                    ) : (
                      <Circle aria-hidden />
                    )}
                  </button>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <div id="after-image-slider-controls" />
    </section>
  );
}
