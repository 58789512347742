import React from "react";
import EmailForm from "../../components/email-form/EmailForm";
import "./Contact.css";

function Contact() {
  return (
    <>
      <div className="contact-page-container">
       
        <div className="contact-page-title">Kontakt formular</div>        
            <EmailForm />
        <br/>
      </div>
    </>
  );
}

export default Contact;


