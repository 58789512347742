import i18next from "i18next";
import translationHR from "./hr/common.json"
import translationEN from "./en/common.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "hr", // default language
  resources: {
    hr: { common: translationHR },
    en: { common: translationEN },
  },
});

export default i18next;
