import React, { useState, useEffect } from "react";
import "./service-pages.css"
import { CiCircleChevRight } from "react-icons/ci";
import {Link} from "react-router-dom"
import { useNavigate } from "react-router-dom"; 

function Nadzor(props) {

  const navigate = useNavigate(); 

  return (
    <div className='services-wrapper'>
      <div className='services-main-content--right'>

            <div className='subtitle'>
                Nadzor pri gradnji obuhvaća
            </div>
            <div className='service--step-list--container'>
              <ul className='service--step-list'>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                      Nadzor građenja da bude u skladu s rješenjem o uvjetima građenja, 
                      potvrđenim glavnim projektom, odnosno građevinskom dozvolom.
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  utvrditi je li glavni projekt u pogledu horizontalnih i vertikalnih gabarita i namjene građevine izrađen u skladu s rješenjem o uvjetima građenja, odnosno lokacijskom dozvolom
                  </div>
                </li>
                <div className='separator--horizontal'></div>     
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  utvrditi je li iskolčenje građevine obavila osoba ovlaštena za obavljanje poslova državne izmjere i katastra nekretnina prema posebnom zakonu
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  utvrditi ispunjava li izvođač uvjete za obavljanje djelatnosti građenja propisane posebnim zakonom, odrediti provedbu kontrolnih postupaka u pogledu ocjenjivanja sukladnosti, odnosno dokazivanja kvalitete određenih dijelova građevine putem ovlaštene osobe
                  </div>
                </li>
                <div className='separator--horizontal'></div>
                <li className='service--step-list--item'>
                  <CiCircleChevRight className='service--step-list--icon'/>
                  <div className='service--step-list--icon--text---standard'>
                  bez odlaganja upoznati investitora sa svim nedostacima odnosno nepravilnostima koje uoči tijekom građenja, a investitora i građevinsku inspekciju i druge inspekcije o poduzetim mjerama, sastaviti završno izvješće o izvedbi građevine
                  </div>
                </li>
                <div className='separator--horizontal'></div>
               
                <li>
                  <div>
                    <div className='subtitle'>
                      Troškovi nadzora pri gradnji
                    </div>
                    <ul className='service--step-list--contacts'>
                    <li className='service--step-list--item'>
                      <div>
                        <span>Kontaktirajete nas i izračunat ćemo za vas ukupne troškove projektiranja vašeg objekta!</span>
                      </div>
                    </li>
                    <li className='service--step-list--icon---contact' >
                    <Link to="/contact">        
                        <button 
                            className="service--step-list--icon---contact--btn"
                            onClick={() => navigate('/contact')} 
                        >
                            KONTAKT
                        </button>
                    </Link>
                </li>
              </ul>
             </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  
  )
}

export default Nadzor