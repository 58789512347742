import React, { useState, useEffect } from "react";
import "./Cards.css";
import CardItem from "../card-item/CardItem";

import imgContact from "./images/team-contactus.svg";
import card2 from "./images/cards2.jpg";
import card4 from "./images/cards4.jpg";
import card5 from "./images/cards5.jpg";
import card6 from "./images/cards6.jpg";
import card7 from "./images/cards7.jpg";

function Cards(props) {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setSmall(window.scrollY > 180));
    }
  }, []);

  return (
    <div className="cards">
      <h1 className={`page--undertitle ${small ? "small" : ""}`}>
        Pročitajte više o uslugama koje pružamo ...
      </h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src={card5}
              text="Pogedajte naše usluge za sve vrste stambenih projektiranja."
              label="PROJEKTIRANJE"
              path="/services/projektiranje"
            />
            <CardItem
              src={card7}
              text="Vršimo usluge nadzora pri gradnji, zakonski uvjeti za građenje."
              label="NADZOR PRI GRADNJI"
              path="/services/nadzor"
            />
            <CardItem
              src={card2}
              text="Pravne, ekonomske, urbanističke, građevinske procjene objekta."
              label="PROCJENA I SAVJETOVANJE"
              path="/services/procjena"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={card6}
              text="Sređivanje zemljišno – knjižno stanje Vašeg stana, prostora, itd..."
              label="ETAŽIRANJE"
              path="/services/etaziranje"
            />
            <CardItem
              src={card4}
              text="Savjetovanje za sve u vezi legalizacije Vašeg objekta."
              label="LEGALIZACIJA"
              path="/services/legalizacija"
            />
            <CardItem
              src={imgContact}
              text="Obratite nam se sa povjerenjem. Mi smo tu za Vas!"
              label="KONTAKTIRAJTE NAS"
              path="/contact"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
